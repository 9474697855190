import { EditorSDK, MainActionDefinition } from '@wix/platform-editor-sdk';
import { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { isEligibleForUpgrade } from '../../utils/editor';
import { hasExternalDashboardUrl } from '../../utils/utils';
import componentJson from './.component.json';
import { IWebComponent } from './elements/customElement';

export const SETTINGS_EV_ID = `${componentJson.id}:settings`;

export async function configureWidgetManifest(
  appManifestBuilder: AppManifestBuilder,
  editorSDK: EditorSDK,
  appDefinitionId: string
) {
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string }) => comp.type === 'WEB'
      )) ||
    {};

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  const { connectLabel } = webComponent.data || {};

  const hasExternal = hasExternalDashboardUrl(appData);

  const isUpgrade: boolean = await isEligibleForUpgrade(
    editorSDK,
    appDefinitionId
  );

  appManifestBuilder.configureWidget(componentJson.id, (widgetBuilder) => {
    widgetBuilder.set({
      nickname: componentJson.id,
      displayName: componentJson.id,
    });
    widgetBuilder
      .gfpp()
      .set('mainAction1', { actionId: 'connect', label: 'Settings' })
      .set('mainAction2', { behavior: 'HIDE' })
      .set('help', {
        id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
      })
      .set('animation', { behavior: 'HIDE' });
  });

  webComponents.forEach((comp) => {
    const behaviorForAction2: MainActionDefinition | any = hasExternal
      ? {
        behavior: 'HIDE',
      }
      : {
        actionId: 'dashboard',
        label: 'Manage',
      };

    appManifestBuilder.configureWidget(
      comp.componentId ?? 'webComponent',
      (widgetBuilder) => {
        widgetBuilder.set({
          displayName: comp.data?.widget?.name ?? comp.componentId,
          nickname: comp.componentId,
        });
        widgetBuilder
          .gfpp()
          .set('mainAction1', {
            actionId: 'connect',
            label: connectLabel || 'Settings',
          })
          .set('mainAction2', behaviorForAction2)
          .set('help', {
            id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
          })
          .set('upgrade', {
            behavior: isUpgrade && appData.appDefinitionId !== '826670ba-3a6b-4157-ac72-7c4fca9ce220' ? 'DEFAULT' : 'HIDE',
          })
          .set('animation', { behavior: 'HIDE' });
      }
    );
  });
}
